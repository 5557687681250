<div class="profile-footer">
  <img
    class="profile-image"
    [src]="imageUrl"
    alt="Profile Image"
    (click)="toggleProfileMenu()"
  />
  <div class="user-info" *ngIf="!isCollapsed">
    <span class="user-name">{{ userName }}</span>
  </div>
  <img
    *ngIf="!isCollapsed"
    [src]="profileMenuIcon"
    alt="Submenu icon"
    (click)="toggleProfileMenu()"
  />
  <div class="profile-menu" *ngIf="isActive">
    <app-profile-menu
      (onTapEvent)="onProfileMenuTapListener($event)"
    ></app-profile-menu>
  </div>
</div>
