<mat-sidenav-container class="sidebar-container" [hasBackdrop]="false">
  <mat-sidenav
    [class.collapsed]="isCollapsed"
    class="sidebar-menu"
    #sidenav
    [disableClose]="true"
    mode="push"
    opened="true"
    [ngStyle]="sidenavposition === 'start' ? { left: '4px' } : {}"
  >
    <button
      class="collapse-icon"
      mat-icon-button
      aria-label="Collapse icon button"
      (click)="toggleCollapse()"
    >
      <mat-icon [svgIcon]="isCollapsed ? 'expand' : 'collapse'"></mat-icon>
    </button>
    <div class="sidebar-header" [class.collapsed]="isCollapsed">
      <div class="icon-images">
        <img [src]="iconBasePath + 'logo.svg'" alt="CoStaff logo icon" />
        <img
          *ngIf="!isCollapsed"
          [src]="iconBasePath + 'logo-text-icon.svg'"
          alt="CoStaff"
        />
      </div>
    </div>

    <div class="primary-nav-bar" [class.collapsed]="isCollapsed">
      <app-nav-bar
        [menuItems]="main_sidebar_items"
        [isCollapsed]="isCollapsed"
      ></app-nav-bar>
    </div>
    <div class="profile-footer">
      <app-profile-footer
        [imageUrl]="'../../assets/images/sample_profile_image.jpeg'"
        [isCollapsed]="isCollapsed"
      ></app-profile-footer>
    </div>
  </mat-sidenav>
  <mat-sidenav-content
    #sidebarContent
    class="sidebar-content"
    [class.collapsed]="isCollapsed"
  >
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </mat-sidenav-content>
</mat-sidenav-container>
