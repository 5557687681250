// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

// Components
import { LoaderComponent } from './components/loader/loader.component';
import { NavBarComponent } from './components/sidebar/navbar/navbar.component';
import { SideBarComponent } from './components/sidebar/sidebar.component';
import { CardComponent } from './components/cards/cards.component';
import { HeaderCardComponent } from './components/cards/header-card/header-cards.component';
import { TableComponent } from './components/table/table.component';
import { CheckBoxComponent } from './components/checkbox/checkbox.component';
import { ProgressBarComponent } from './components/progress-bar/progress.component';
import { NotificationModalComponent } from './components/modals/notification-modal/notification-modal.component';
import { ButtonToggleComponent } from './components/button-toggle/button-toggle.component';
import { FlatButtonComponent } from './components/buttons/flat-button/flat-button.component';
import { StrokedButtonComponent } from './components/buttons/stroked-button/stroked-button.component';
import { PrimaryButtonComponent } from './components/buttons/primary-button/primary-button.component';
import { SuccessButtonComponent } from './components/buttons/success-button/success-button.component';
import { AccentButtonComponent } from './components/buttons/accent-button/accent-button.component';
import { CautionButtonComponent } from './components/buttons/caution-button/caution-button.component';
import { ErrorButtonComponent } from './components/buttons/error-button/error-button.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { ToolTipComponent } from './components/tooltips/tooltip.component';
import { PrimaryLinkComponent } from './components/links/primary-link.component';
import { PublicHeaderComponent } from './components/public/header/header.component';
import { SignupCardComponent } from './components/cards/signup-card/signup-card-component';
import { PublicFooterComponent } from './components/public/footer/footer.component';
import { BodyCardComponent } from './components/cards/body-card/body-cards.component';
import { BadgeComponent } from './components/badges/badge.component';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';
import { MenuItemComponent } from './components/sidebar/menu-item/menu-item.component';
import { ProfileFooterComponent } from './components/public/footer/profile-footer/profile-footer/profile-footer.component';
import { ProfileHeaderComponent } from './components/public/header/profile-header/profile-header.component';
import { RowItemComponent } from './components/profile-menu/row-item/row-item.component';
import { emptyStateComponent } from './components/empty-state/empty-state.component';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu/profile-menu.component';
import { ShortCardComponent } from './components/cards/short-card/short-card.component';
import { UploadModalComponent } from './components/modals/upload-modal/upload-modal.component';

// Material
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [
    SideBarComponent,
    NavBarComponent,
    CardComponent,
    HeaderCardComponent,
    TableComponent,
    LoaderComponent,
    CheckBoxComponent,
    ProgressBarComponent,
    ButtonToggleComponent,
    RadioButtonComponent,
    NotificationModalComponent,
    UploadModalComponent,
    ToolTipComponent,
    FlatButtonComponent,
    StrokedButtonComponent,
    PrimaryButtonComponent,
    SuccessButtonComponent,
    AccentButtonComponent,
    CautionButtonComponent,
    ErrorButtonComponent,
    PrimaryLinkComponent,
    PublicHeaderComponent,
    PublicFooterComponent,
    SignupCardComponent,
    BodyCardComponent,
    BadgeComponent,
    MenuItemComponent,
    ProfileFooterComponent,
    ProfileHeaderComponent,
    PasswordStrengthComponent,
    emptyStateComponent,
    ProfileMenuComponent,
    RowItemComponent,
    ShortCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonToggleModule,
    MatInputModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatRadioModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatDividerModule,
  ],
  exports: [
    SideBarComponent,
    NavBarComponent,
    CardComponent,
    HeaderCardComponent,
    TableComponent,
    LoaderComponent,
    CheckBoxComponent,
    ProgressBarComponent,
    ButtonToggleComponent,
    RadioButtonComponent,
    NotificationModalComponent,
    UploadModalComponent,
    ToolTipComponent,
    FlatButtonComponent,
    StrokedButtonComponent,
    PrimaryButtonComponent,
    SuccessButtonComponent,
    AccentButtonComponent,
    CautionButtonComponent,
    ErrorButtonComponent,
    PrimaryLinkComponent,
    PublicHeaderComponent,
    PublicFooterComponent,
    SignupCardComponent,
    BodyCardComponent,
    BadgeComponent,
    MenuItemComponent,
    PasswordStrengthComponent,
    ProfileFooterComponent,
    emptyStateComponent,
    ProfileMenuComponent,
    ProfileHeaderComponent,
    RowItemComponent,
    ShortCardComponent
  ],
  providers: [FormGroupDirective],
})
export class SharedModule {}
