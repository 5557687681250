import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-empty-state',
  template: `
      <div class="empty-state-div  {{newHeight}}">
         <img src="{{imageSrc}}" alt="" width="300px">

         <div class="empty-state-text-div" [ngClass]="{'grid-class': !secondLineTextNeeded}">
            <h3>
               {{firstLineText}}
               <br>
               <span *ngIf="secondLineTextNeeded">
                  {{secondLineText}}
               </span>
            </h3>
            <div class="button">
               <app-stroked-button *ngIf="!secondLineTextNeeded"
                  [iconButton]="true"
                  [matIconSvg]="true"
                  [matIconText]="true"
                  [iconBeginning]="true"
                  (btnClick)="onClick()"
                  [text]="buttonText"
                  [strokedPrefixSvg]="matPrefixSvgValue"
                  [svgSrc]="buttonSvgSrc"
                  [hideToolTip]="true"
                  >
               </app-stroked-button>
            </div>
         </div>
      </div>
  `,
  styleUrls: ['../../../styles/empty-state.css']
})

export class emptyStateComponent implements OnInit {
   @Input() imageSrc: string = '';
   @Input() buttonSvgSrc: string = '';
   @Input() newHeight: string = '';
   @Input() buttonText: string = '';
   @Input() matPrefixSvgValue!: string;
   @Input() secondLineTextNeeded: boolean = true;
   @Input() firstLineText: string = 'This page is under';
   @Input() secondLineText: string = 'construction';
   @Output() btnClick = new EventEmitter();

   constructor() { }

   ngOnInit(): void { }

   onClick() {
      this.btnClick.emit();
   }
}