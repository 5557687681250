import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  template: `
    <div *ngFor="let option of options">
      <mat-checkbox class="check-box" [disableRipple]="true" [(ngModel)]="option.checked" (change)="checkRow()">
        <span class="check-box-value" *ngIf="option.showLabel">
          {{ option.label }}
        </span>
      </mat-checkbox>
    </div>
  `,
  styleUrls: ['../../../styles/checkbox.css']
})
export class CheckBoxComponent implements OnInit {

  @Input() options!: { id: number, label: string, checked: boolean, showLabel: boolean }[];
   @Output() checkedRow = new EventEmitter<{ id: number, label: string, checked: boolean }[]>();

   constructor() { }

   ngOnInit(): void { }

   checkRow() {
      this.checkedRow.emit(this.options);
   }
}
